<template>
  <div class="ids grey lighten-3">
    <h1>Schueler</h1>
    {{ msg }}
  </div>
</template>

<script>
export default {
  name: 'VPSchueler',
  props: {
    msg: String
  }
}
</script>

<style>
  div.ids {
    height: 100%;
  }
</style>